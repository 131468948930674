





















































































































































import { Vue, Component } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import newsStore from "@/store/modules/newsFeed";
import helpers from "@/utils/helpers";

@Component({
  components: {},
})
export default class NewsFeedList extends Vue {
  news: any[] = [];
  filter: {
    page: number;
    newsCategory?: string;
    title?: string;
  } = {
    page: 1,
  };

  momentDate(date: any) {
    return helpers.toMomentFormat(date);
  }

  get newsFeed() {
    return newsStore.paginatedNewsList;
  }

  async handlePageChange(val: number) {
    this.filter.page = val;
    this.search(val);
  }

  async created() {
    let vm = this;
    await newsStore.getAllNewsFeed(this.filter);
    this.news = newsStore.newsList;
    // this.newsFeed = JSON.parse(JSON.stringify(newsStore.paginatedNewsList));
  }

  get AdminRouter() {
    return AdminRouter;
  }

  indexMethod(index: any) {
    return (index = index + 1);
  }

  handleClose(row: any) {
    this.$confirm(`Are you sure to Delete ${row.Title} News?`)
      .then((_) => {
        //newsStore.deleteNewsFeed(row);
        this.$snotify.success("News  Deleted Successfully");
      })
      .catch((_) => {});
  }

  async search(page: number = 1) {
    this.filter.page = page;
    await newsStore.getAllNewsFeed(this.filter);
  }
  clearFilter() {
    this.filter = { page: 1 };
    this.search();
  }
}
